import React from "react";
import mapFancyNames from "./utils/ClassCategoryFancyNamesMapping";
import { SubscriberStatusConstants } from './utils/constants';
import logger from "../src/utils/logger"
import { validateEmail,isEmbeddedMode } from './utils/utilityfunctions'
const config = require("./assets/config.json");



class Localytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = { SessionActive: true };
    }
    customDimensions(position, dimension) {
        window.ll("setCustomDimension", position, dimension);
    }
    setEmbededMode(){
        if(isEmbeddedMode()){
            window.ll('setCustomDimension', 10, '1');
        }
        else{
            window.ll('setCustomDimension', 10, '0');
        }
    }
    componentDidUpdate(prevProps) {

        if (
            (this.props.userDetail !== null) &&
            (prevProps.userDetail !== this.props.userDetail)
        ) {
            const userData = this.props.userDetail.applicationProfile;
            if (userData.emailAddress && validateEmail(userData.emailAddress)) {
                window.ll(
                    "setCustomerEmail",
                    `${userData.emailAddress}`
                );
            }

            window.ll("setProfileAttribute", "First Name", `${userData.firstName}`, "app");
            window.ll("setProfileAttribute", "Last Name", `${userData.lastName}`, "app");

        }

        if ((prevProps.isAuthenticated !== this.props.isAuthenticated) && (this.props.isAuthenticated !== null)) {
            //Setting Autheticated Custom Dimension to Localytics
            if (this.props.isAuthenticated) {

                //
                window.ll("setCustomerId", localStorage.getItem("userId"));
                logger("setting Authenticated status 1 ==>>" + new Date());
                window.ll('setCustomDimension', 1, '1');
                window.ll("setProfileAttribute", "Authenticated", "True", "app");


                if (config.isThirdPartyEnabled === true) {
                    const ExternalId = localStorage.getItem("ExternalUserId")
                    window.ll('setCustomDimension', 9, ExternalId);
                    window.ll("setProfileAttribute", "External Id", ExternalId, "app");
                    //setting external id in local storage for subsequent login

                }

            } else {
                logger("setting Authenticated status 0 ==>>" + new Date());
                window.ll('setCustomDimension', 1, '0');
                logger("setting subscription status ==>> UNKNOWN" + new Date());
                window.ll('setCustomDimension', 2, 'UNKNOWN');
                window.ll("setProfileAttribute", "Authenticated", "False", "app");
                window.ll("setProfileAttribute", "Subscriber Status", "UNKNOWN", "app")


                //Deleting localytics customer id from Localstorage
                localStorage.setItem("_loc_ids", null);
                /**
                 * Nikhil Gupta 07th July 2020
                 * modify the local storage custom dimension value to initial for logout
                 * Reason: value stored in storage shd not be sent to localytics
                 */
                var customDimensionObj = JSON.parse(localStorage.getItem("_loc_cd"))

                if (config.isThirdPartyEnabled === true) {
                    localStorage.setItem("_loc_cd", (JSON.stringify([customDimensionObj[0], 0, 'UNKNOWN', 'UNKNOWN', new Date("0001").toDateString(),
                    new Date("0001").toDateString(), 'UNKNOWN', 0, 'UNKNOWN', 'UNKNOWN'])))
                } else {
                    localStorage.setItem("_loc_cd", (JSON.stringify([customDimensionObj[0], 0, 'UNKNOWN', 'UNKNOWN', new Date("0001").toDateString(),
                    new Date("0001").toDateString(), 'UNKNOWN', 0, 'UNKNOWN'])))
                }
            }
            this.setEmbededMode();
        }
        const getClassDurationSec = (input)=>{
            try {
                return input.ClassDetails.duration ? input.ClassDetails.duration * 60 : input.liveStreamClassDetails?.durationSecond ?? 0
            } catch (error) {
              return 0
            }
        }
        if (prevProps.PlayDuration !== this.props.PlayDuration) {
            if (this.props.ClassDetails !== null && (this.props.ClassDetails && this.props.ClassDetails.id)) {
                if (this.props.clubConnectClicked) {
                    logger("Event ID " + this.props.ClassDetails.eventId)
                    window.ll("tagEvent", "Live Connect Class Performed", {
                        "Class ID": this.props.ClassDetails.id,
                        "Class Name": this.props.ClassDetails.title,
                        "Class Duration": this.ClassDurationTimeBand(
                            this.props.ClassDetails.duration
                        ),
                        "Play Duration": this.aroundTimeBand(this.props.PlayDuration),
                        "Class Duration Seconds": getClassDurationSec(this.props),
                        "Play Duration Seconds": this.props.PlayDuration,
                        "Live": false,
                        "Start Date": new Date(),
                        "Event ID": this.props.ClassDetails.eventId ? this.props.ClassDetails.eventId : 0
                    });
                }
                else if (this.props.ClassDetails.id) {
                    window.ll("tagEvent", "On Demand Class Performed", {
                        "Class ID": this.props.ClassDetails.id,
                        "Class Name": this.props.ClassDetails.title,
                        "Class Duration": this.ClassDurationTimeBand(
                            this.props.ClassDetails.duration
                        ),
                        "Play Duration": this.aroundTimeBand(this.props.PlayDuration),
                        "Class Duration Seconds": this.props.ClassDetails.duration * 60,
                        "Play Duration Seconds": this.props.PlayDuration,
                        "Class Category": mapFancyNames(
                            this.props.ClassDetails.category !== undefined ?
                                this.props.ClassDetails.category[0] :
                                this.props.ClassDetails.classCategory[0]
                        ),
                        "Provider ID": this.props.ClassDetails.newProviderId,
                        "Provider Name": this.props.ClassDetails.provider_id,
                        "Channel ID": this.props.ClassDetails.channelId
                    });
                }
            }
        }
        /**
        * Nikhil Gupta
        * 29th June 2020
        * WDP-25
        * On Demand Class Viewed
        */
        if (this.props.ClassDetails !== null && (this.props.ClassDetails && this.props.ClassDetails.id)
        ) {
            if (!prevProps.ClassDetails || prevProps.ClassDetails.id !== this.props.ClassDetails.id) {
                if (!this.props.clubConnectClicked) {
                    window.ll("tagEvent", "On Demand Class Viewed", {
                        "Class ID": this.props.ClassDetails.id,
                        "Class Name": this.props.ClassDetails.title,
                        "Class Duration": this.ClassDurationTimeBand(
                            this.props.ClassDetails.duration
                        ),
                        "Class Category": mapFancyNames(
                            this.props.ClassDetails.category !== undefined ?
                                this.props.ClassDetails.category[0] :
                                this.props.ClassDetails.classCategory[0]
                        ),
                        "Provider ID": this.props.ClassDetails.newProviderId,
                        "Provider Name": this.props.ClassDetails.provider_id,
                        "Channel ID": this.props.ClassDetails.channelId
                    });

                }
                else if(this.props.ClassDetails.id){
                    const classCategory =  this.props.ClassDetails?.classCategory ?? []
                    const category = this.props.ClassDetails?.category ?? []
                    window.ll("tagEvent", "On Demand Class Viewed", {
                        "Class ID": this.props.ClassDetails.id,
                        "Class Name": this.props.ClassDetails.title,
                        "Class Duration": this.ClassDurationTimeBand(
                            this.props.ClassDetails.duration
                        ),
                        "Class Category": mapFancyNames(
                            this.props.ClassDetails.category !== undefined ?
                                category[0] ?? "" : //used nullcolizer
                                classCategory[0] ?? "" //used nullcolizer
                        ),
                        "Provider ID": this.props.ClassDetails.newProviderId,
                        "Provider Name": this.props.ClassDetails.provider_id,
                        "Channel ID": this.props.ClassDetails.channelId
                    });
                }
            }
        }
        if (prevProps.isClassStarted !== this.props.isClassStarted && (this.props.isClassStarted === true)) { //inequality changed to equality
            if (this.props.ClassDetails !== null && (this.props.ClassDetails && this.props.ClassDetails.id)
            ) {
                if (this.props.clubConnectClicked) {
                    logger("Event ID " + this.props.ClassDetails.eventId)
                    window.ll("tagEvent", "Live Connect Class Started", {
                        "Class ID": this.props.ClassDetails.id,
                        "Live": false,
                        "Class Name": this.props.ClassDetails.title,
                        "Class Duration": this.ClassDurationTimeBand(
                            this.props.ClassDetails.duration
                        ),
                        "Start Date": new Date(),
                        "Event ID": this.props.ClassDetails.eventId ? this.props.ClassDetails.eventId : 0

                    });
                }
                else if (this.props.ClassDetails.id) {
                    window.ll("tagEvent", "On Demand Class Started", {
                        "Class ID": this.props.ClassDetails.id,
                        "Class Name": this.props.ClassDetails.title,
                        "Class Duration": this.ClassDurationTimeBand(
                            this.props.ClassDetails.duration
                        ),
                        "Class Category": mapFancyNames(
                            this.props.ClassDetails.category !== undefined ?
                                this.props.ClassDetails.category[0] :
                                this.props.ClassDetails.classCategory[0]
                        ),
                        "Provider ID": this.props.ClassDetails.newProviderId,
                        "Provider Name": this.props.ClassDetails.provider_id,
                        "Channel ID": this.props.ClassDetails.channelId
                    });
                }

            }
        }
        /**
        * Nikhil Gupta
        * 29th June 2020
        * CF-8154
        * Capture Subscription related details
        */
        if (prevProps.userSubscription !== this.props.userSubscription && (this.props.userSubscription !== null)) {
            if (prevProps.userSubscription == null ||
                prevProps.userSubscription?.status !== this.props.userSubscription?.status) {
                if (config.isThirdPartyEnabled) {
                    logger("Subscription Type", "SSO");
                    window.ll("setProfileAttribute", "Subscription Type", "SSO", "app")
                    window.ll('setCustomDimension', 3, "SSO");

                    logger("Subscription Start Date", new Date("0001"))
                    window.ll("setProfileAttribute", "Subscription Start Date", new Date("0001"), "app")
                    window.ll('setCustomDimension', 4, new Date("0001").toDateString());

                    logger("Subscription End Date", new Date("0001"))
                    window.ll('setCustomDimension', 5, new Date("0001"));
                    window.ll("setProfileAttribute", "Subscription End Date", new Date("0001").toDateString(), "app")

                    logger("Subscription Interval", "UNKNOWN")
                    window.ll("setProfileAttribute", "Subscription Interval", "UNKNOWN", "app")
                    window.ll('setCustomDimension', 6, "UNKNOWN");

                    logger("Subscription Interval Count", 0)
                    window.ll("setProfileAttribute", "Subscription Interval Count", 0, "app")
                    window.ll('setCustomDimension', 7, 0);

                    logger(`Capture FreeAccessCode ==>> UNKNOWN`);
                    window.ll('setCustomDimension', 8, "UNKNOWN");
                    window.ll("setProfileAttribute", "Access Code", "UNKNOWN", "app")

                } else {
                    if (config.AccessCodeMandatory) {
                        logger("Subscription Type", "FAC")
                        window.ll("setProfileAttribute", "Subscription Type", "FAC", "app")
                        window.ll('setCustomDimension', 3, "FAC");
                        if (this.props.userSubscription.discountCode) {
                            logger('Capture FreeAccessCode ==>>' + this.props.userSubscription.discountCode);
                            window.ll('setCustomDimension', 8, this.props.userSubscription.discountCode);
                            window.ll("setProfileAttribute", "Access Code", this.props.userSubscription.discountCode, "app")

                        }
                        this.sendSubscriptionDataToLocalytics(this.props.userSubscription.status)
                    }
                    else if (this.props.userSubscription.status === "trialing" || this.props.userSubscription.trialPeriodStart) {
                        logger("Subscription Type", "Trial")
                        window.ll("setProfileAttribute", "Subscription Type", "Trial", "app")
                        window.ll('setCustomDimension', 3, "Trial");

                        logger(`Capture FreeAccessCode ==>> UNKNOWN`);
                        window.ll('setCustomDimension', 8, "UNKNOWN");
                        window.ll("setProfileAttribute", "Access Code", "UNKNOWN", "app")

                        this.sendSubscriptionDataToLocalytics(this.props.userSubscription.status)
                    }
                    else {

                        logger("Subscription Type", "Paid")
                        window.ll("setProfileAttribute", "Subscription Type", "Paid", "app")
                        window.ll('setCustomDimension', 3, "Paid");

                        logger('Capture FreeAccessCode ==>> UNKNOWN');
                        window.ll('setCustomDimension', 8, "UNKNOWN");
                        window.ll("setProfileAttribute", "Access Code", "UNKNOWN", "app")

                        this.sendSubscriptionDataToLocalytics(this.props.userSubscription.status)
                    }
                }
            }
        }
        if (this.props.subscriptionStatus !== null && (prevProps.subscriptionStatus !== this.props.subscriptionStatus)) {

            /**
             * SubscriberStatusConstants
             *   INACTIVE: 0
             *   ACTIVE: 1
             *   UNKNOWN: 2
             */
            if (this.props.subscriptionStatus === SubscriberStatusConstants.INACTIVE) {
                logger("setting subscription status INACTIVE ==>>" + new Date());
                window.ll('setCustomDimension', 2, 'INACTIVE');
                window.ll("setProfileAttribute", "Subscriber Status", "INACTIVE", "app")
            } else if (this.props.subscriptionStatus === SubscriberStatusConstants.ACTIVE) {

                logger("setting subscription status ACTIVE ==>>" + new Date());
                window.ll('setCustomDimension', 2, 'ACTIVE');
                window.ll("setProfileAttribute", "Subscriber Status", "ACTIVE", "app")
            } else {
                logger("setting subscription status ==>> UNKNOWN" + new Date());
                window.ll('setCustomDimension', 2, 'UNKNOWN');
                window.ll("setProfileAttribute", "Subscriber Status", "UNKNOWN", "app")
            }
            //Sending User Login Event to Localytics
            logger('Sending login event with delay ==>>' + new Date());
            var userId = localStorage.getItem("userId");
            var token = localStorage.getItem("token");
            if (prevProps.subscriptionStatus === null && (userId !== null && token !== null)) {

                window.ll("tagEvent", "User Login", {
                    "Client ID": localStorage.getItem("clientId"),
                    "User ID": userId
                });


            }
        }
        /**
         * Nikhil Gupta 24th Sept 2020
         * live streaming events (SL, Live, SVod)
         */
        const getEventType = () => {
            const type =this.props?.recordedClasses?.type
            let eventType 
            switch (type) {
                case 1:
                    eventType = "Simulated Live Event"
                    break;
                case 2:
                    eventType = "Live Event"
                    break;
                case 3:
                    eventType ="Video On Demand Event"
                    break;
            
                default:
                    eventType = "Unspecified"
                    break;
            }
            return eventType
        }
        
        if (this.props.recordedClasses) {
            if (!prevProps.recordedClasses) {
                if (this.props.recordedClasses.eventDetail?.isStreaming === true) {
                    window.ll("tagEvent", "Live Connect Class Started", {
                        "Class ID": this.props.recordedClasses.event_id,
                        "Live": true,
                        "Class Name": this.props.recordedClasses.eventDetail.title,
                        "Start Date": new Date(),
                        "Event ID": this.props.recordedClasses.event_id,
                        "Event Type": this.props.recordedClasses.eventDetail.eventType
                    })
                } else {
                    window.ll("tagEvent", "Live Connect Class Viewed", {
                        "Class ID": this.props.recordedClasses.event_id,
                        "Live": true,
                        "Class Name": this.props.recordedClasses.eventDetail.title,
                        "Start Date": this.props.recordedClasses.eventOccurrenceDate ?
                            this.props.recordedClasses.eventOccurrenceDate :
                            this.props.recordedClasses.eventDetail.eventOccurrenceDate,
                        "Event ID": this.props.recordedClasses.event_id,
                        "Event Type": this.props.recordedClasses.eventDetail.eventType

                    })
                }
            }
            else {
                if (prevProps.recordedClasses.eventDetail.isStreaming !== this.props.recordedClasses.eventDetail.isStreaming) {
                    if (this.props.recordedClasses.eventDetail.isStreaming === true) {
                        window.ll("tagEvent", "Live Connect Class Started", {
                            "Class ID": this.props.recordedClasses.event_id,
                            "Live": true,
                            "Class Name": this.props.recordedClasses.eventDetail.title,
                            "Start Date": new Date(),
                            "Event ID": this.props.recordedClasses.event_id,
                            "Event Type":this.props.recordedClasses.eventDetail.eventType
                        })
                    } else {
                        window.ll("tagEvent", "Live Connect Class Viewed", {
                            "Class ID": this.props.recordedClasses.event_id,
                            "Live": true,
                            "Class Name": this.props.recordedClasses.eventDetail.title,
                            "Start Date": this.props.recordedClasses.eventViewDate ?
                                this.props.recordedClasses.eventViewDate :
                                this.props.recordedClasses.eventDetail.eventOccurrenceDate,
                            "Event ID": this.props.recordedClasses.event_id,
                            "Event Type": this.props.recordedClasses.eventDetail.eventType

                        })
                    }
                }
            }
        }
        if(prevProps.isLiveStreamStarted!==this.props.isLiveStreamStarted&&this.props.recordedClasses){
            window.ll("tagEvent", "Live Connect Class Started", {
                "Class ID": this.props.recordedClasses.event_id,
                "Live": true,
                "Class Name": this.props.recordedClasses.eventDetail.name,
                "Start Date": new Date(),
                "Event ID": this.props.recordedClasses.event_id,
                "Event Type": getEventType()
            })
        }
        if (prevProps.LSPlayDuration !== this.props.LSPlayDuration) {
            if (this.props.liveStreamClassDetails && this.props.liveStreamClassDetails.event_id) {
                const getClassDurationMinutes = () => {
                   try {
                        const isTrueLiveEvent = (this.props.liveStreamClassDetails?.type ==="true-live")
                        const calcMinutes=isTrueLiveEvent ? 0 : Math.floor( this.props.liveStreamClassDetails?.durationSecond/60)
                        const minutes = this.props.ClassDetails?.duration ?? calcMinutes
                   return this.ClassDurationTimeBand( minutes);}
                   catch(e) {
                       return 0;
                   }
                }
                window.ll("tagEvent", "Live Connect Class Performed", {
                    "Class ID": this.props.liveStreamClassDetails.event_id,
                    "Live": true,
                    "Class Name": this.props.liveStreamClassDetails.eventDetail.title,
                    "Start Date": new Date(),
                    "Event ID": this.props.liveStreamClassDetails.event_id,
                    "Event Type":this.props.liveStreamClassDetails.eventDetail.eventType,
                    "Play Duration": this.aroundTimeBand(this.props.LSPlayDuration),
                    "Play Duration Seconds": this.props.LSPlayDuration,
                    "Class Duration": getClassDurationMinutes(),
                    "Class Duration Seconds": this.props.liveStreamClassDetails?.durationSecond ?? 0,

                });
            }
        }
        /**
        * Nikhil Gupta
        * 29th June 2020
        * WDP-25
        * Channel Viewed
        */
        if (this.props.channelDetails && this.props.channelDetails.channelDetails) {
            if(this.props.ClassDetails?.fromRecent !== true){
                let channelDetails = this.props.channelDetails.channelDetails
                if (!prevProps.channelDetails ||
                    prevProps.channelDetails.channelDetails.tag !== this.props.channelDetails.channelDetails.tag) {
                    window.ll("tagEvent", "Channel Viewed", {
                        "Channel Id": channelDetails.tag,
                        "Channel Name": channelDetails.name,
                        "Provider Id": channelDetails.provider,
                        // "Provider Name":
                    });
                }
            }
        }

        /**
        * Nikhil Gupta
        * 29th June 2020
        * WDP-25
        * Category Viewed
        */
        if (this.props.categoryDetails) {
            if (!prevProps.categoryDetails ||
                prevProps.categoryDetails.categoryId !== this.props.categoryDetails.categoryId) {
                window.ll("tagEvent", "Category Viewed", {
                    "Category Id": this.props.categoryDetails.id,
                    "Category Name": this.props.categoryDetails.name,
                });
            }
        }
        if(config.loadEvent === true){
            if(this.props.webPlayerEvents.LoadEvent && !this.props.webPlayerEvents.loaded && this.props.webPlayerEvents.isUserLoaded && this.props.webPlayerEvents.error === false){
                this.webPlayerLoadEvent();
            }
            if(this.props.webPlayerEvents.LoadEvent && this.props.webPlayerEvents.error){
                this.webPlayerLoadEvent();
                this.props.configErrorLoaded();
            }
            if(this.props.webPlayerEvents.LoadEvent && !this.props.webPlayerEvents.tenantConfigLoaded){
                this.webPlayerLoad();
            }
        }
    }

    sendSubscriptionDataToLocalytics = (userSubscriptionStatus) => {

        logger("Subscription Start Date", new Date(this.props.userSubscription.subscriptionStartDate))
        if (this.props.userSubscription.subscriptionStartDate) {
            window.ll("setProfileAttribute", "Subscription Start Date", new Date(this.props.userSubscription.subscriptionStartDate), "app")
            window.ll('setCustomDimension', 4, this.props.userSubscription.subscriptionStartDate);

        }

        if (userSubscriptionStatus === "canceled") {
            logger("Subscription End Date", new Date(this.props.userSubscription.subscriptionEndDate))
            if (this.props.userSubscription.subscriptionEndDate) {
                window.ll('setCustomDimension', 5, this.props.userSubscription.subscriptionEndDate);
                window.ll("setProfileAttribute", "Subscription End Date", new Date(this.props.userSubscription.subscriptionEndDate), "app")
            }
        } else {
            if (config.AccessCodeMandatory) {
                if (this.props.userSubscription.facCodeEndDate) {
                    logger("Subscription End Date", new Date(this.props.userSubscription.facCodeEndDate))
                    window.ll('setCustomDimension', 5, this.props.userSubscription.facCodeEndDate);
                    window.ll("setProfileAttribute", "Subscription End Date", new Date(this.props.userSubscription.facCodeEndDate), "app")
                }
                else {
                    logger("Subscription End Date", new Date("2200"))
                    window.ll('setCustomDimension', 5, new Date("2200").toDateString());
                    window.ll("setProfileAttribute", "Subscription End Date", new Date("2200"), "app")
                }
            } else {
                logger("Subscription End Date", new Date(this.props.userSubscription.currentPeriodEnd))
                if (this.props.userSubscription.currentPeriodEnd) {
                    window.ll('setCustomDimension', 5, this.props.userSubscription.currentPeriodEnd);
                    window.ll("setProfileAttribute", "Subscription End Date", new Date(this.props.userSubscription.currentPeriodEnd), "app")
                }
            }
        }

        //signUp
        if (this.props.subscriptionProduct?.length > 0) {
            var planObj = this.props.subscriptionProduct[0].plans.find(
                (plan) => this.props.userSubscription.planTag === plan.tag)
            logger("Subscription Interval", planObj.interval)
            if (planObj.interval) {
                window.ll("setProfileAttribute", "Subscription Interval", planObj.interval, "app")
                window.ll('setCustomDimension', 6, planObj.interval);
            }

            logger("Subscription Interval Count", planObj.intervalCount)
            if (planObj.intervalCount) {
                window.ll("setProfileAttribute", "Subscription Interval Count", planObj.intervalCount, "app")
                window.ll('setCustomDimension', 7, planObj.intervalCount);
            }

        }
        //login
        else {
            logger("Subscription Interval", this.props.userSubscription.interval)
            if (this.props.userSubscription.interval) {
                window.ll("setProfileAttribute", "Subscription Interval", this.props.userSubscription.interval, "app")
                window.ll('setCustomDimension', 6, this.props.userSubscription.interval);
            }
            logger("Subscription Interval Count", this.props.userSubscription.intervalCount)
            if (this.props.userSubscription.intervalCount) {
                window.ll("setProfileAttribute", "Subscription Interval Count", this.props.userSubscription.intervalCount, "app")
                window.ll('setCustomDimension', 7, this.props.userSubscription.intervalCount);
            }

        }
    }
    ClassDurationTimeBand = min => {
        if (min <= 60) {
            //let min = sec / 60;
            if (min === 0) {
                return "0-1"
            }
            let nearestInt = Math.ceil(min / 10);

            let maxValue = nearestInt * 10;

            return `${maxValue === 10 ? 0 : maxValue - 9}-${maxValue}`;
        } else if (min > 60) {
            return "61+";
        }
    };
    aroundTimeBand = sec => {
        if (sec <= 60) {
            return "0-1";
        } else if (sec > 60 && sec <= 300) {
            return "1-5";
        } else if (sec > 300 && sec <= 600) {
            return "6-10";
        } else if (sec > 600 && sec <= 3660) {
            let min = sec / 60;
            let nearestInt = Math.ceil(min / 10);

            let maxValue = nearestInt * 10;

            return `${maxValue - 9}-${maxValue}`;
        } else if (sec > 3660) {
            return "61+";
        }
    };
    componentWillUnmount() {
        this.setState({ SessionActive: false });
    }
    componentDidMount() {
        // document.cookie = 'jwt=; domain=.localytics.com; Path=/;expires=' + new Date(0).toUTCString();
        // document.cookie = '_biz_sid=; domain=.localytics.com; Path=/;';
        // document.cookie = 'ei_client_id=; domain=.localytics.com; Path=/;';

        let localyticsInitSet;
        if (localStorage.getItem("userId") !== null &&
            localStorage.getItem("token") !== null) {
            //If user is logged in se customer id in init method else only CDs
            localyticsInitSet = {
                customDimensions: [localStorage.getItem("clientId")],
                customerId: localStorage.getItem("userId"),
            }
        } else {
            localyticsInitSet = {
                customDimensions: [localStorage.getItem("clientId"), '0', 'UNKNOWN', 'UNKNOWN', new Date("0001").toDateString(),
                new Date("0001").toDateString(), 'UNKNOWN', 0, 'UNKNOWN'],
                customerId: '',

            }

            if (config.isThirdPartyEnabled === true) {
                localyticsInitSet = {
                    customDimensions: [localStorage.getItem("clientId"), '0', 'UNKNOWN', 'UNKNOWN', new Date("0001").toDateString(),
                    new Date("0001").toDateString(), 'UNKNOWN', 0, 'UNKNOWN', 'UNKNOWN'],
                    customerId: '',

                }
            }
            localStorage.setItem("_loc_ids", null);
        }
        const  customDimensions = [...localyticsInitSet.customDimensions];
        customDimensions[10] = isEmbeddedMode() ? '1' : '0';
        const updatedData = { ...localyticsInitSet,customDimensions };
        localyticsInitSet = updatedData;
        //--------------------------------------------------Localytics INIt--------------------

        (function (l, y, t, i, c, s) {
            l['LocalyticsGlobal'] = i;
            l[i] = function () {
                (l[i].q = l[i].q || []).push(arguments)
            };
            l[i].t = +new Date();
            (s = y.createElement(t)).type = 'text/javascript';
            s.src = config.localytics ?? 'https://web.localytics.com/v3/localytics.min.js';
            (c = y.getElementsByTagName(t)[0]).parentNode.insertBefore(s, c);
            window.ll('init', process.env.REACT_APP_LOCALYTICS_APP_ID, {
                ...localyticsInitSet,
                sessionTimeout: parseInt(process.env.REACT_APP_LOCALYTICS_TIMEOUT)
            });
        }(window, document, 'script', 'll')) ;


    }
    webPlayerLoad(){
        const data = {
            "Request URL":window.location.origin+sessionStorage.getItem("embededConfig"),
            "Tenant ID":this.props.tenantID,
            "User Agent":navigator.userAgent,
            // deviceBandwidth:'',
            // remoteAddressIP:''
        };
        this.props.configEventLoaded(data);
        window.ll("tagEvent","ZLog Web Player Initialisation", data);
    }
    webPlayerLoadEvent = ()=>{
        const time =Math.abs((this.props.webPlayerEvents.appLoadStartTime - this.props.webPlayerEvents.appLoadEndTime) / 1000);
        const data = {
                "User ID":this.props.webPlayerEvents.userId,
                "Request URL":window.location.origin+sessionStorage.getItem("embededConfig"),
                "Tenant ID":this.props.tenantID,
                "User Agent":navigator.userAgent,
                // deviceBandwidth:'',
                // remoteAddressIP:'',
                "Request Start Time":new Date(this.props.webPlayerEvents.appLoadStartTime),
                "Request End Time":new Date(this.props.webPlayerEvents.appLoadEndTime),
                "Total Time":time,
                "Tenant Config Response Time":this.props.webPlayerEvents.tenantConfigResponseTime,
                "Login API Response Time":this.props.webPlayerEvents.loginAPIResponseTime,
                "Circuit API Response Time":this.props.webPlayerEvents.externalLoginResponseTime,
                // "Error Message":this.props.webPlayerEvents?.error ?? ''
            };
        this.props.eventLoaded();
        this.props.configUserLoaded();
        window.ll("tagEvent","ZLog Web Player Load",data );
       }

    render() {
        return <React.Fragment > {this.props.children} </React.Fragment>;
    }
}
export default Localytics

