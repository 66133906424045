import React from "react"

function StarColor(){
    const color = (require("../assets/config.json")).primaryColor.slice(1)

    const src = `<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="m16 23.36 5.533 3.346c1.014.613 2.254-.293 1.987-1.44l-1.467-6.293 4.894-4.24c.893-.774.413-2.24-.76-2.334l-6.44-.546-2.52-5.947c-.454-1.08-2-1.08-2.454 0l-2.52 5.933-6.44.547c-1.173.093-1.653 1.56-.76 2.333l4.894 4.24-1.467 6.294c-.267 1.146.973 2.053 1.987 1.44L16 23.359z" id="lm8p73ljda"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <mask id="cws1ocnfib" fill="%23fff">
            <use xlink:href="%23lm8p73ljda"/>
        </mask>
        <g mask="url(%23cws1ocnfib)" fill="%23${color}">
            <path d="M0 0h32v32H0z"/>
        </g>
    </g>
</svg>`

    return (
    <img className="rating-star"
    src={`data:image/svg+xml;utf8,${src}`} 
    alt="backButton"/>
    )
}

export default StarColor
