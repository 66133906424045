import React from 'react'
import { useIconSize } from '../utils/svgSize';

function Star(props) {
    const getSize = useIconSize(["40px","32px",'32px',"32px"], props.id);
  return (
    <svg 
    className="makeTextClickable"
    id={props.id}
    onMouseEnter={()=>{props.mouseIn()}}
    onMouseLeave={()=>{props.mouseOut()}}
    width={getSize} height={getSize} viewBox={`0 0 ${getSize} ${getSize}`}xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="m16 23.36 5.533 3.346c1.014.613 2.254-.293 1.987-1.44l-1.467-6.293 4.894-4.24c.893-.774.413-2.24-.76-2.334l-6.44-.546-2.52-5.947c-.454-1.08-2-1.08-2.454 0l-2.52 5.933-6.44.547c-1.173.093-1.653 1.56-.76 2.333l4.894 4.24-1.467 6.294c-.267 1.146.973 2.053 1.987 1.44L16 23.359z" id="lm8p73ljda"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <mask id="cws1ocnfib" fill="#fff">
            <use xlinkHref="#lm8p73ljda"/>
        </mask>
        <g mask="url(#cws1ocnfib)" fill={props.color}>
            <path d="M0 0h32v32H0z"/>
        </g>
    </g>
</svg>

  )
}

export { Star }