import React from 'react';

function EditIcon(props) {

const src = `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<defs>
    <path d="M2.999 17.461v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15l10.92-10.91-3.75-3.75-10.91 10.91c-.1.1-.15.22-.15.36zm17.71-10.42a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" id="dzzh3lw47a"/>
</defs>
<g fill="none" fill-rule="evenodd">
    <mask id="9sollx6b9b" fill="%23fff">
        <use xlink:href="%23dzzh3lw47a"/>
    </mask>
    <g mask="url(%239sollx6b9b)" fill="%23FFF">
        <path d="M0 0h24v24H0z"/>
    </g>
</g>
</svg>`

return (<div className='icon-svg icon-rating' onClick={props.onClick}>
<img className="icon-editor"
src={`data:image/svg+xml;utf8,${src}`} 
alt="editIcon"/>
</div>
)
}

export { EditIcon }