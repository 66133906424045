import React from "react"

function StarEmpty(){
    const color = (require("../assets/config.json")).primaryColor.slice(1)

    const src = `<svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <path d="m26.198 12.393-6.453-.56-2.52-5.934c-.454-1.08-2-1.08-2.454 0l-2.52 5.947-6.44.547c-1.173.093-1.653 1.56-.76 2.333l4.894 4.24-1.467 6.293c-.267 1.147.973 2.054 1.987 1.44l5.533-3.333 5.533 3.347c1.014.613 2.254-.294 1.987-1.44l-1.467-6.307 4.894-4.24c.893-.773.426-2.24-.747-2.333zm-10.2 8.48-5.013 3.026 1.333-5.706-4.427-3.84 5.84-.507 2.267-5.373 2.28 5.386 5.84.507-4.427 3.84 1.334 5.707-5.027-3.04z" id="yzmq3yblra"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
        <mask id="hwek5rj6lb" fill="%23fff">
            <use xlink:href="%23yzmq3yblra"/>
        </mask>
        <g mask="url(%23hwek5rj6lb)" fill="%23${color}">
            <path d="M0 0h32v32H0z"/>
        </g>
    </g>
    </svg>`

    return (
    <img className="rating-star"
    src={`data:image/svg+xml;utf8,${src}`} 
    alt="emptyStar"/>
    )
}

export default StarEmpty
