/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import { Translate } from "../../utils/Translate";
import { Avatar, Grid , Typography } from '@material-ui/core';
import AccountButtonDotsMobile from "./AccountButtonDotsMobile";
import Routes from '../../components/Router/Routes';
import IconSearch from "../../CommonImages/icon-search";
import {SearchItems} from "../../components/SearchItems";
import { isEmpty } from "lodash";
const config = require("../config.json")

class LoggedInTexts extends React.Component {
  constructor(props){
    super(props);
    this.handelSearch= this.handelSearch.bind(this);
    this.handelShowResultPage= this.handelShowResultPage.bind(this);
    this.checkOnSearchPage= this.checkOnSearchPage.bind(this);
    this.getStateData= this.getStateData.bind(this);
    this.handelGoBackToScreen= this.handelGoBackToScreen.bind(this);
    this.closeSearch= this.closeSearch.bind(this);
    // this.currentPath = this.props.history.location?.pathname;
    this.findString = "/search/";
    this.state = this.getStateData()
  }
  checkOnSearchPage(){
    return window.location.pathname.includes(this.findString);
  }
  getStateData(){
    const showSearch = this.checkOnSearchPage();
    let [,searchText] = showSearch ? window.location.pathname.split(this.findString) : '';
    if(searchText) searchText = decodeURI(searchText);
    return  {showSearch,searchText}
  }
  handelSearch(){
    if((window.location.href.includes("classes/search")|| !(window.location.href.includes("search") ))){
      this.props.handelSearchBox(true);
    }
  }
  closeSearch(){
    this.props.handelSearchBox(false);
  }
  handelGoBackToScreen(){
      const {pageFrom,setSearchQuery,history} = this.props,
      isSearchPage = this.checkOnSearchPage(),
      info = {visitPage:"/home",text:''};
      if(isSearchPage){
        info.text = this.state?.searchText ?? ''
        if(!isEmpty(pageFrom)){
          info.visitPage = pageFrom;
          info.text = "";
        }
      }
      else{
        info.visitPage = window.location.pathname;
      }
      setSearchQuery(info.text,info.visitPage)
      history.push({pathname:info.visitPage})
  }
  handelShowResultPage(){
    const inputEle = document.querySelector(".text-box input");
    if(inputEle?.value?.length){
      const query = encodeURI(inputEle?.value ?? '')
      const isSearchPage = this.checkOnSearchPage()
      this.props.setSearchQuery(query,isSearchPage ? this.props.pageFrom : window.location.pathname)
      this.props.history.push({pathname:`/search/${query}`})
      this.props.handelSearchBox(false);
    }
  }
  handleClickToFocus=()=>{
    const inputEle = document.querySelector(".text-box input");
    inputEle.focus()
  }
  render() {
    return (
      <React.Fragment>
        <div className="loginButtonHolder">
          <Grid container justifyContent="flex-end" alignItems="center">
            {this.props.showSearch === false &&
            <>
            {(config.showLiveEvent || config.ShowLiveConnect) &&
              <Grid item>
                <a
                  className={
                    this.props.history.location.pathname === Routes.connectClasses || 
                    (this.props.history.location.pathname.indexOf("live") > -1) || 
                    (this.props.history.location.pathname.indexOf("connect") > -1)
                      ? `headerLinks-fitness dynamiclinks`
                      : "headerLinks-fitness"
                  }
                  onClick={() => {
                    config.showLiveEvent ?
                      this.props.history.push({ pathname: Routes.connectLiveSchedule }) :
                      this.props.history.push({ pathname: Routes.connectOndemandClasses })

                  }}
                >
                  <Typography variant="button">{Translate({ id: "navigation.ConnectTopNav" })}</Typography>
                </a>
              </Grid>}
            <Grid item>
              <a
                className={
                  this.props.history.location.pathname === Routes.classListViewRoute||this.props.history.location.pathname === Routes.searchRoute ||
                    (window.location.href.indexOf("categories") > -1)
                    ? `headerLinks-fitness dynamiclinks`
                    : "headerLinks-fitness"
                }
                onClick={() => {
                  this.props.history.push({ pathname: Routes.classListViewRoute });
                }}
              >
                <Typography variant="button">{Translate({ id: "navigation.ClassesTopNav" })}</Typography>
              </a>
            </Grid>
            {config.showChannels && <Grid item>
              <a
                className={
                  this.props.history.location.pathname === "/channels" || (window.location.href.indexOf("channels") > -1)
                    ? `headerLinks-fitness dynamiclinks`
                    : "headerLinks-fitness"
                }
                onClick={() => {
                  this.props.history.push({ pathname: "/channels" });
                }}
              >
                <Typography variant="button">{Translate({ id: "navigation.ChannelsTopNav" })}</Typography>
              </a>
            </Grid>}
            <Grid item>
              <a
                className={
                  this.props.history.location.pathname === Routes.collections || (window.location.href.indexOf("collection") > -1)
                    ? `headerLinks-fitness dynamiclinks`
                    : "headerLinks-fitness"
                }
                style={{
                  marginRight: "10px"
                }}
                onClick={() => {
                  this.props.history.push({ pathname: Routes.collections });
                }}
              >
                <Typography variant="button">{Translate({ id: "navigation.CollectionsTopNav" })}</Typography>
              </a>
            </Grid>
            {(window.location.href.includes("classes/search")|| !(window.location.href.includes("search") )) &&
              <Grid item id="search-icon" className="item-search">
              <div className="icon-search" onClick={this.handelSearch}>
                <IconSearch />
              </div>
            </Grid>
            }
            {(window.location.href.includes("search")) && !(window.location.href.includes("classes/search")) &&
            <Grid item className="item-search">
            <div className="icon-search dynamicLink" onClick={this.handleClickToFocus}>
              <IconSearch color={config.primaryColor}/>
            </div>
            </Grid>
            }
            <Grid item>
              <Avatar style={{ cursor: "pointer" }} alt="Travis Howard"
                // src={avatarImg}
                onClick={() => {
                  this.props.history.push({ pathname: Routes.favClassRoute });
                }}
              />
            </Grid>
            </>}
            {this.props.showSearch === true && 
             (window.location.href.includes("classes/search")|| !(window.location.href.includes("search") )) &&
            <Grid item id="search-box" className="item-search">
              <SearchItems
                focus
                handelBack={this.handelShowResultPage}
                closeSearch={this.closeSearch}
                loading={this.props.globalSearchLoading}
                showSearch={this.props.showSearch}
                crossIconId={"crossIconId"}
                />
              </Grid> }
          </Grid>
        </div>

        <div className="loginButtonHolderMobile pull-right">
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <Avatar alt="Travis Howard"
                onClick={() => {
                  this.props.history.push({ pathname: Routes.favClassRoute });
                }}
              />
            </Grid>
            <AccountButtonDotsMobile
              searchText={this.state.searchText}
              handelBack={this.handelShowResultPage}
              loading={this.props.globalSearchLoading}
              isSearchPage={this.checkOnSearchPage()}
            />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
export default LoggedInTexts;
