import React from 'react'
import { IconButton, MenuItem, Menu, Typography } from '@material-ui/core';
import { Translate } from "../../utils/Translate";
import { withRouter } from 'react-router-dom';
import { useSelector } from "react-redux"
import Routes from '../../components/Router/Routes'
import MoreVertical from "../../CommonImages/more-vert"
import { Close } from '@material-ui/icons';
import { SearchItems } from '../../components/SearchItems';
const config = require("../config.json")


const ITEM_HEIGHT = 48;
const AccountButtonDotsMobile = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const isAuthenticated = useSelector(state => state.auth.token !== "");
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handelBack =()=>{
        const inputEle = document.querySelector(".comp-search .text-box input");
        if(inputEle?.value?.length){
            props.handelBack();
            handleClose();
        }
    }
    return (

        !isAuthenticated ? (
            <div>
                {/* <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className="kebab"
                >
                    <MoreVertical />

                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                        },
                    }}
                    style={{ top: "25px", left: "-10px" }}
                >
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            props.history.push({ pathname: "/signup" });
                        }}
                    >
                        <Typography variant="button">{Translate({ id: "layout.SignUp" })}</Typography>
                    </MenuItem>

                </Menu> */}
            </div>
        ) : (<div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="kebab"
            >
                {anchorEl!==null ? <Close className='icon-close' width={30} height={30} /> : 
                <MoreVertical />}
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                //keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 200,
                    },
                }}
                style={{ top: "40px", left: "-10px" }}

            >
                {!props.isSearchPage &&
                <MenuItem className='search-items'>
                    <SearchItems
                        handelBack={handelBack}
                        loading={props.loading}
                        noCrossIcon
                    />
                </MenuItem>}
                {(config.showLiveEvent || config.ShowLiveConnect) &&
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            config.showLiveEvent ?
                                props.history.push({ pathname: Routes.connectLiveSchedule }) :
                                props.history.push({ pathname: Routes.connectOndemandClasses })
                        }}
                    >
                        <Typography variant="button">{Translate({ id: "navigation.ConnectTopNav" })}</Typography>
                    </MenuItem>}
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        props.history.push({ pathname: Routes.classListViewRoute });
                    }}
                >
                    <Typography variant="button">{Translate({ id: "navigation.ClassesTopNav" })}</Typography>
                </MenuItem>
                {config.showChannels && <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        props.history.push({ pathname: "/channels/" });
                    }}
                >
                    <Typography variant="button">{Translate({ id: "navigation.ChannelsTopNav" })}</Typography>
                </MenuItem>}
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        props.history.push({ pathname: Routes.collections });
                    }}
                >
                    <Typography variant="button">{Translate({ id: "navigation.CollectionsTopNav" })}</Typography>
                </MenuItem>
            </Menu>
        </div>)
    )
}

export default withRouter(AccountButtonDotsMobile);
