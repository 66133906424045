export const authenticatePlatformUserAPI = "/oauth/authenticateplatformuser";
export const thirdPartyUserOnWexerApi = "oauth/login"
export const creatThirdParyUserOnWexerAPI = "/oauth/signup";
export const LoginAPI="/oauth/login"

//export const authenticatePlatformUserAPI = "/oauth/authenticatewebuser/";
export const consentAPI = "/api/v2/consent";
export const userConsentAPI = "/api/v2/users/consent";
export const saveUserConsentAPI = "/api/v2/users/consent"; 
export const getUserDetailAPI = "/api/v3/users";
export const sendContactUsMail = "/api/v1/ContactUs";
export const updateUserDetails = "/api/v3/users";
export const forgetPassword = "api/v2/forgotpassword";
export const resetPassword = "api/v2/resetpassword";

export const onDemandDeepLinkClassAPI = "/api/v2/virtualclasses";
export const onDemandSearchMetadataAPI = "/api/v2/users/ondemand/metadata";
export const onDemandCollectionAPI = "/api/v2/users/ondemand/collections";
export const onDemandSearchAPI = "/api/v2/virtualclass/search";
export const onDemandNewSearchAPI = "/api/v3/virtualclass/search/";
export const intensityAPI = "/api/v2/intensitycodes";
export const logOnDemandTrackingAPI = "/api/v2/ondemandtracking";
export const logWorkoutResult = "/api/v4/users/workoutresults";
export const getUserRecentActivity = "/api/v2/users/workoutresults";

//liveStream --> adding trailer link to live stream videos on my activity
export const putUserRecentActivity = "/api/v3/users/workoutresults";

export const setClassFavourite = "/api/v2/users/preferredvirtualclass";

export const getSubscriptionProductAPI = "/api/v2/products";
export const getUserSubscriptionAPI = "/api/v1/users/subscriptions";
export const getUserSubscriptionDetailsAPI = "/api/v2/users/subscriptions";
export const FreeTrailAPI = "/api/v1/payments/trial";
export const purchaseSubscriptionAPI = "/api/v1/payments/subscription";
export const cancelUserSubscriptionAPI = "/api/v1/user/subscription";
export const resumeUserSubscriptionAPI = "/api/v1/payments/subscription";
export const getFreeAccessSubscription = "api/v2/subscription";
export const verifyFreeAccessCode = "api/v1/verify/freeaccess/";

export const createPlatformUserAPI = "/api/v1/createplatformuser";
export const createUserWithUserIdAPI = "/api/v1/identity/login";
export const clubLiveConnectAPI = "/api/v1/ondemand/connect/"

export const clubLiveConnectAPIFortenantAsProvider = "api/v1/connect/events"

export const collectionByTagAPI = "ondemand/v1/collections/"
export const getTenantConfigAPI = "/api/v1/tenants/";

export const confirmationEmail = "/api/v1/campaign/sendemail/"


/// Channels  
//RR - Same api can be used with channel tag as well, to obtaine titles of that that channel Ex -  
//{{BaseUri}}/api/v1/channels/1c600c92-2afb-420c-aa49-eb2c3e03a283"

export const getAllChannels = "/api/v1/channels/"

//RR - API to retrive a single class detail along with Channel information-  
//api/v1/channels/titles/{titleId}"
export const getDeeplinkedChannelClass = "api/v1/titles"

/*COTD */
export const getCOTDHomePageData = "/api/v2/users/ondemand/cotdcollection";

export const getCategoryClassesUnderOnDemandClass = "/api/v3/virtualclass/";

//nikhil
//fetch the FLL events from the PaperProps
export const fetchFLLEvents = "/api/v1/connect/events"
export const fetchFLLEventsV4 = "/v4/events"

export const fetchFLLAutoPublishVideos = "/api/v2/ondemand/connect/"
export const fetchEventDetailsV4 = "/v4/event/access"
export const fetchEventDataV4 = "/v4/event"

export const playbackURL = "staff/v2/ondemandclasses/"

//Review and Rating
export const fetchUserRating = "/v4/getratingandreview"
export const updateUserRating = "/v4/ratingreview"

