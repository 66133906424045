import Layout from "../containers/Layout/Layout"
import { resetSubscriptionStatus } from '../store/actions/subscription';
import { AuthenticateThirdPartyUser } from '../assets/ClubManager/action'
import * as actionTypes from "../store/actions/actionTypes";
import { logout } from "../store/actions/auth";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { handelSearchBox, setGlobalSearch } from "../store/actions/globalSearch";

const mapStateToProps = state => {
    return {
      // userSubscription: state.subscription.userSubscripton,
      // userActiveSubscription: getUserActiveSubscription(state),
      isAuthenticated: state.auth.token,
      notifyUser: state.auth.notifyUser,
      NotificationText: state.auth.NotificationText,
      NotificationType: state.auth.NotificationType,
      isPlayerOn: state.onDemand.isClassStarted,
      deepLinkPayload: state.auth.deepLinkPayload,
      //isUserAuthenticated: state.clubManagerReducer && state.clubManagerReducer.ssoUserAuthentication,
      isSubscriptionActive: state.subscription.isSubscriptionActive,
      pageFrom:state.globalSearch?.pageFrom,
      showSearch:state.globalSearch?.showSearch,
      globalSearchLoading:state.globalSearch?.loading
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      onLogout: () => dispatch(logout()),
      CloseNotification: () => dispatch({ type: actionTypes.CLOSE_NOTIFICATION }),
      resetSubscriptionStatus: () => dispatch(resetSubscriptionStatus()),
      AuthenticateThirdPartyUser: () => dispatch(AuthenticateThirdPartyUser()),
      setSearchQuery:(searchText,pathName)=> dispatch(setGlobalSearch(searchText,pathName)),
      handelSearchBox:(_)=> dispatch(handelSearchBox(_))
    };
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(injectIntl(Layout)))