import React, { useEffect } from "react";
import { Translate } from "../../utils/Translate";
import { Typography, Grid, Select, MenuItem } from "@material-ui/core";
import Routes from "../Router/Routes"
import Config from "../../assets/config.json"
const config = require("../../assets/config.json")
// 3rd Dec,2019 by Nikhil
// Expected latest Design
// Commented the code for adding these items to the footer
function Footer(props) {
  const {language,copyright,supportedLanguages=[],langauageSelector=false} = Config;
  const version = localStorage.getItem("version");
  const [open, setOpen] = React.useState(false);
  useEffect(()=>{
    if(langauageSelector){
      const selectedLanguage = localStorage.getItem("language");
      if(!supportedLanguages.includes(selectedLanguage)){
        localStorage.setItem("language",language);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  const languageValues =  supportedLanguages.map((items)=>{
    const id = "language."+items;
    return Translate({ id,defaultMessage:items,values:items })
  });
  const handleChange = (event) => {
    localStorage.setItem("language",event.target.value);
    window.location.reload();
  };

  const copyrightValue =()=>{
    const currentYear = new Date().getFullYear()
    return copyright ?? `© Wexer ${currentYear}` //fallback in case api doesn't pass value
  }
  return (
    <footer>
      <div className="Footer">
      {/* <div className="alignleft">
      {langauageSelector && supportedLanguages.length &&
      <>
      <Typography variant="h3">
          <a href={Routes.homePage}>{Translate({ id: "labelforlanguageswitcher.SiteLanguageLabel" })}</a>
          &nbsp;|&nbsp;
          <Select
          labelId="langauage-selector-label"
          id="langauage-selector"
          open={open}
          onClose={()=>setOpen(false)}
          onOpen={()=>setOpen(true)}
          value={localStorage.getItem("language")}
          onChange={handleChange}
        >
          {supportedLanguages.map((suportedLanguage,index)=>
            <MenuItem className="langauage-switcher" value={suportedLanguage}>
              {languageValues[index] ?? suportedLanguage}
            </MenuItem>
          )}
        </Select>
      
        </Typography>
        </>
      }
        <Typography variant="h3">
          <a href={Routes.homePage}>Content</a>
        </Typography>
        <Typography variant="h3">
          <a href={Routes.homePage} className="footer-h3">{Translate({ id: "footer.home" })}</a>
        </Typography>
        <Typography variant="h3">
          <a href="/contact" className="footer-h3">{Translate({ id: "footer.contactUs" })}</a>
        </Typography>
        <Typography variant="h3">
          <a href="/tc" className="footer-h3">{Translate({ id: "footer.TermsAndConditions" })}</a>
        </Typography>
        <div className="clear">&nbsp;</div>
        
      </div> */}
      <div className="d-flex align-item-baseline ">
        <div className="container-1" >
      <Typography variant="h3">
      {Translate({ id: "footer.Content" })}
        </Typography>
      {(config.showChannels === true || config.showChannels === undefined) && <Typography variant="h3">
          <a href="/channels" className="footer-h3">{Translate({ id: "homePage.ChannelsTitle" })}</a>
        </Typography>}
      <Typography variant="h3">
          <a href={Routes.collections} className="footer-h3">{Translate({ id: "homePage.CollectionsTitle" })}</a>
        </Typography>
      {(config.showBrowseClasses === true || config.showBrowseClasses === undefined)&& <Typography variant="h3" >
            <a href={Routes.searchRoute} className="footer-h3">{Translate({ id: "ClassListViews.Search" })}</a>
          </Typography>}
        </div>
        <div className="container-2">
      <Typography variant="h3">
      {Translate({ id: "footer.Support" })}
        </Typography>
        <Typography variant="h3">
          <a href="/contact" className="footer-h3">{Translate({ id: "footer.contactUs" })}</a>
        </Typography>
        <Typography variant="h3">
          <a href="/tc" className="footer-h3">{Translate({ id: "footer.TermsAndConditions" })}</a>
        </Typography>
        </div>
      <div className="lang-sel-cont">
        {langauageSelector && supportedLanguages.length &&
      <>
      <Typography variant="h3" className="language-switcher">
          {Translate({ id: "labelforlanguageswitcher.SiteLanguageLabel" })}
          <br/>
          <Select
          labelId="langauage-selector-label"
          id="langauage-selector"
          open={open}
          onClose={()=>setOpen(false)}
          onOpen={()=>setOpen(true)}
          value={localStorage.getItem("language")}
          onChange={handleChange}
        >
          {supportedLanguages.map((suportedLanguage,index)=>
            <MenuItem className="langauage-switcher" value={suportedLanguage}>
              {languageValues[index] ?? suportedLanguage}
            </MenuItem>
          )}
        </Select>
      
        </Typography>
        </>
      }
      </div>
      </div>
      {/* <Grid container>
        <Grid item lg={6} md={6} sm={6} xs={12}>
        <p className="text-white">{copyrightValue()}{" "}{Translate({ id: "footer.Copyright" })}</p>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
        <p className="textLeft-xs text-white text-right">
        {Translate({ id: "footer.Build" })}{": "}
            {version} 
        </p>
        </Grid>
      </Grid> */}
      </div>
      <div className="d-flex justify-content-spacebtw footer-lower">
      <p>{copyrightValue()}{Translate({ id: "footer.Copyright" })}</p>
      <p className="textLeft-xs text-right">
        {Translate({ id: "footer.Build" })}{": "}
            {version} 
        </p>
      </div>
    </footer>
  );
}

export default Footer;
