import * as actionTypes from '../../store/actions/actionTypes'
import api from './Api'
import userApi from './userApi'
import * as APIConstants from './Apiconstants'
import * as wexerAPIConstants from '../../store/APIConstants'
import { ConsentType } from '../../utils/constants'
import { Translate } from '../../utils/Translate'
import logger from "../../utils/logger"
import { AuthenticateWexerUser } from '../../store/actions/auth'
import axios from 'axios';
import environment from "./config";

const URL = environment.BASE_URL
const configJSON = require('../../assets/config.json')

//By Nikhil on 25th Nov, 2019
//Replicated the code of AuthenticateWexerUser as we need to hit the wexer server for authentication
//in future, we can have different authentication server for the tenant
// export const AuthenticateThirdPartyUser = (uname, pwd) => {
//   return dispatch => {
//     dispatch(AuthenticateWexerUser(uname, pwd))
//   }
// }
const clientID = process.env.REACT_APP_API_CLIENT_ID
const clientSecret = process.env.REACT_APP_API_CLIENT_SECRET
const signature = clientID + ':' + clientSecret
const base64Signature = btoa(signature)
const Authorization = `Basic ${base64Signature}`;
export const createThirdPartyUser = (data) => {
  return dispatch => {}
};
export const AuthenticateThirdPartyUser = (uname, pwd) => {
  return dispatch => {

  };
}

export const GetConsentDetail = () => {
  return dispatch => {
    api.get(wexerAPIConstants.consentAPI).then(response => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_CONSENT_SUCCESS,
          consents: response.data
        })
      }
    })
  }
}

//By Nikhil on 26h Nov, 2019
//This method is used to get the user contracts from the third party server.

export const getThirdPartyUserSubscription = () => {

  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_USER_SUBSCRIPTION_START
    });
    const userId = localStorage.getItem("userId")
    axios.get(URL + APIConstants.getUserSubscriptionAPI , {
       headers: {
          'Authorization': Authorization,
          'content-type': 'application/json',
          'TenantId': window.localStorage.getItem('clientId'),
          'X-EXTERNAL-IDENTIFIER': userId
        }
    }).then(response => {
      if (response.status === 200) {
        if (response.data.length > 0) {
          if (configJSON.accessServiceTag) {
            response.data = response.data.filter((x) => {
              return x.Cservice.includes(configJSON.accessServiceTag)
            })
          }
          let activeStatusObj = response.data.find((data) => data.Status == "active")
          if (activeStatusObj) {
            dispatch({
              type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
              status: true,
            });
          }
          else {
            logger(`userSusbcription error ${response}`)
            dispatch({
              type: actionTypes.AUTH_FAIL,
              error: Translate({ id: 'ApiResponseStrings.GenericError' })
            });
            dispatch({
            type: actionTypes.SHOW_ERROR_PAGE_SSO,
            showErrorPageSSO: true
          });
          }
        }
      }
      else{
      dispatch({
        type: actionTypes.AUTH_FAIL,
        error: Translate({ id: 'ApiResponseStrings.GenericError' })
      });
      dispatch({
      type: actionTypes.SHOW_ERROR_PAGE_SSO,
      showErrorPageSSO: true
      });}
    }).catch(function (er) {
      logger(`userSusbcription catch ${er}`)
      dispatch({
        type: actionTypes.AUTH_FAIL,
        error: Translate({ id: 'ApiResponseStrings.GenericError' })
      });
      dispatch({
      type: actionTypes.SHOW_ERROR_PAGE_SSO,
      showErrorPageSSO: true
      });
    });
  };
}

export const UpdateUserProfileData = data => {
  return dispatch => {
    var body = {
      ApplicationProfile: {
        FirstName: data.firstName,
        LastName: data.lastName,
        emailAddress: data.email
      }
    }



    dispatch({
      type: actionTypes.UPDATE_USER_START
    })

    userApi.put(wexerAPIConstants.updateUserDetails, body).then(response => {
      var msg = ''
      let Notification = Translate({
        id: body.ApplicationProfile.FirstName ? 'Profile.ProfileUpdated' : 'Profile.EmailUpdated'
      })
      if (response.status == 200) {
        dispatch(GetUserDetail())
        dispatch({
          type: actionTypes.NOTIFY_USER,
          NotificationText: Notification
        })
        dispatch({
          type: actionTypes.UPDATE_USER_SUCCESS
        })
      } else {
        if (response.status == 409) {
          msg = Translate({ id: 'ApiResponseStrings.EmailaddressInUse' })
        } else {
          msg = Translate({ id: 'ApiResponseStrings.GenericError' })
        }
        dispatch({
          type: actionTypes.UPDATE_USER_FAIL,
          errorMsg: msg
        })
      }
    })
  }
}

export const GetUserConsent = () => {
  return dispatch => {
    // api.setHeader("Authorization", "Bearer");
    userApi.get(wexerAPIConstants.userConsentAPI).then(response => {
      let tncAccepted = false
      if (response.ok) {
        let tncObject = response.data.find(x => x.policy === ConsentType.TNC)
        if (tncObject !== undefined) {
          tncAccepted = tncObject.userAccepted
        }

        dispatch({
          type: actionTypes.FETCH_USER_CONSENT_SUCCESS,
          userConsent: response.data,
          isLatestTncAccepted: tncAccepted
        })
      }
    })
  }
}

export const GetUserDetail = () => {
  return dispatch => {
  }
}
