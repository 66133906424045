import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import LiveStreamCard from "./LiveStreamCard";
import { Grid, Typography } from "@material-ui/core";
import { Translate } from '../../utils/Translate';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Routes from "../../components/Router/Routes"
import CustomGrid from '../CustomGrid/CustomGrid';

import IconRigntArrow from '../../CommonImages/icon-rightArrow';

function LiveStreamHome(props) {
    const {clubClasses=[]} = props;
    //if(clubClasses.length)clubClasses.length = 3;
    const collection = {
        collectionType:"live",
        collectionItems:clubClasses
    }
    const viewDetails = () => {
        props.history.push({
            pathname: Routes.connectLiveSchedule
        });
    };
    return (
        <Fragment>

            <div className="m-t-30">
                <div className='m-b-32 m-b-15'>
                    <div data-testid={"titleClick"} className="align-left d-flex makeTextClickable m-b-7" onClick={viewDetails}>
                        <h1 className="align-left font-h1 ">{Translate({ id: "homePage.LiveScheduleTitle" })} </h1>
                        <IconRigntArrow id='arrow-6' />
                    </div>
                    <h6 className="text-gray font-h6 align-left "  >
                        {Translate({ id: "homePage.LiveScheduleDescription" })}
                    </h6>
                </div>
            </div>

            <div className="m-t-15 m-t-xs-15 m-b-20">
                <div className="live">
                    <div className="mobileScroll">
                        <CustomGrid
                            items={clubClasses} 
                            spacing={2}
                            showLoading={false}
                            isPageContainer={false}
                            classes="cardList flexNoWrap livstreamHome">
                            {(clubData)=>
                                <LiveStreamCard
                                creationDate={clubData.firstDate}
                                nextOccurence={clubData.eventOccurrenceDate}
                                activeTime={clubData.startTime}
                                eventStatus={clubData.isStreaming}
                                trailerLinkWeb={clubData.trailerLink}
                                history={props.history}
                                thumbnail={clubData?.imageUrl ? clubData.imageUrl : ""}
                                title={clubData.title}
                                duration={clubData.eventDuration
                                    ? (clubData.eventDuration)
                                    : clubData.type === "true-live" ? undefined : 55}
                                durationInSec={clubData.eventDuration 
                                    ? Math.ceil((clubData.eventDuration * 60))
                                    : clubData.type === "true-live" ? undefined : 55}
                                description={clubData.description}
                                show={true}
                                id={clubData.id}
                                // clicked={onClubCardClicked}
                                level={clubData.level}
                                isClubConnect={true}
                                action={viewDetails}
                                videoId={clubData.videoId}
                                type={clubData.eventType}
                            />
                            }
                        </CustomGrid>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default withRouter(LiveStreamHome)

